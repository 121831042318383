import React from 'react'
import styled from 'styled-components'

import Layout from '../components/Layout'
import Leadership from '../components/Leadership'
import SlimTitle from '../components/SlimTitle'
import SEO from '../components/SEO'
import SEOPageTitle from '../components/SEOPageTitle'
import Section from '../components/Section'

const P = styled.p`
  font-size: ${props => props.theme.type.sizes.hardSizes.base};
  color: ${props => props.theme.colors.navy};
  line-height: ${props => props.theme.type.lineHeights.base};


  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    font-size: ${props => props.theme.type.sizes.hardSizes.small};
  }
`

const About = () => {
  const seoNode = {
    title: 'Zoopay - About Us',
    description:
      'Zoopay was created in 2013 as a niche FinTech provider for the travel industry.',
  }

  return (
    <Layout>
      <SEOPageTitle title={seoNode.title} />
      <SEO postNode={seoNode} pagePath="about" customTitle customDescription />

      <Section borderBottom={false} paddingBottom={false}>
        <div className="row">
          <div className="col-lg-6 col-12">
            <SlimTitle>About Us</SlimTitle>
          </div>
          <div className="col-lg-6 col-12">
            <P>
              Zoopay was created in 2013, as a niche FinTech provider for the Travel Industry. The company is part of the Currency Solutions Group, which is a leading independent FX and international payments company.
            </P>
            <P>
              Zoopay was created with the belief that we could use technology and payment partners to provide a solution to alleviate the problem travel companies have when trying to manage multi-currency transactions, and make multiple payments. By offering an automated, state of the art technology, we take away the complexity of managing thousands of transactions on a daily basis.
            </P>
            <P>
              As the payment market is constantly evolving it is essential that we, using technological advances, meet our customers growing expectations. Our objective is to transform the future of travel payments by offering a premium service in conjunction with the latest technology.
            </P>
            <P>
              Since being formed, we have taken the time to understand our customer’s needs and have delivered services and products to manage their payments and make their life easier. This has enabled us to transition from supplying a Payment Gateway and Acquiring services, to also providing bespoke currency risk management software to customers.
            </P>
            <P>
              Zoopay is committed to successfully solving the complex challenges our customers are encountering. For us this means delivering both savings, and stability in challenging trading environments. We, in collaboration with key industry partners, are continuing to develop new, and compelling product and service offerings to keep our customers ahead of the curve.
            </P>
          </div>
        </div>
      </Section>
      <Leadership />
    </Layout>
  )
}

export default About
