import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const Span = styled.span`
  color: ${props => props.theme.colors.darkGrey};
  display: block;
  font-family: ${props => props.theme.type.fonts.base};
  font-size: ${props => props.theme.type.sizes.base};
  font-weight: ${props => props.theme.type.weights.bold};
  margin-top: 5px;
  text-transform: initial;

  @media (min-width: ${props => props.theme.responsive.medium}) {
    font-size: ${props => props.theme.type.sizes.small};
  }

  @media (min-width: ${props => props.theme.responsive.large}) {
    font-size: ${props => props.theme.type.sizes.base};
  }
`

const Header = styled.h3`
  margin: 0 1rem;
  padding: 60px 0 0 0;
  font-size: ${props => props.theme.type.sizes.medium};

  @media (min-width: ${props => props.theme.responsive.medium}) {
    font-size: ${props => props.theme.type.sizes.base};
  }

  @media (min-width: ${props => props.theme.responsive.large}) {
    margin: 2rem 0;
    font-size: ${props => props.theme.type.sizes.large};
  }

  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    margin: 0 0 35px;
  }
`

const P = styled.p`
  font-size: ${props => props.theme.type.sizes.hardSizes.base};
  color: ${props => props.theme.colors.navy};
  line-height: ${props => props.theme.type.lineHeights.base};
  letter-spacing: 0.22px;

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    font-size: ${props => props.theme.type.sizes.hardSizes.base};
  }

  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    font-size: ${props => props.theme.type.sizes.hardSizes.small};
    padding-bottom: 35px;
  }
  
`

const LeadershipCard = ({ leader: { biography, image, role, name } }) => (
  <div>
    <Img fluid={image.fluid} />
    <Header>
      {name}
      <Span>{role}</Span>
    </Header>
    <P>{biography.childMarkdownRemark.excerpt}</P>
  </div>
)

export default LeadershipCard
