import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import LeadershipCard from '../LeadershipCard'
import Section from '../Section'
import SectionTitle from '../SectionTitle'

const Flex = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style-type: none;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  width: 100%;

  @media (min-width: ${props => props.theme.responsive.medium}) {
    flex-direction: row;

    li {
      width: 48%;
      max-width: 566px;
    }
  }

  @media (min-width: ${props => props.theme.responsive.large}) {
    flex-direction: row;

    li {
      width: 30%;
      max-width: 566px;
    }
  }
`

const Leadership = () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulLeadership(sort: { fields: [order], order: ASC }) {
          edges {
            node {
              id
              biography {
                childMarkdownRemark {
                  excerpt(pruneLength: 500)
                }
              }
              name
              role
              image {
                title
                fluid(maxWidth: 1000, maxHeight: 1000, quality: 75) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
                ogimg: resize(width: 1000) {
                  src
                  width
                  height
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <Section borderBottom={false} paddingBottom={true}>
          <SectionTitle>Leadership Team</SectionTitle>
          <Flex>
            {data.allContentfulLeadership.edges.map(({ node: leader }) => (
              <li key={leader.id}>
                <LeadershipCard key={leader.id} leader={leader} />
              </li>
            ))}
          </Flex>
        </Section>
      )
    }}
  />
)

export default Leadership
